<template>
  <div class="user_register_confirm">
    <div class="user_register_confirm__body">
      <div class="user_register_confirm__body__top mw-1000">
        <div class="user_register_confirm__body__top__step">
          <span>
            会員登録
          </span>
          <span>
            基本情報登録
          </span>
          <span class="active">
            基本情報確認
          </span>
          <span>
            利用登録完了
          </span>
        </div>
        <p class="user_register_confirm__body__top-title">
          マナビDX Quest 基本情報確認
        </p>
      </div>
      <div class="mw-1000">
        <div class="user_register_confirm__body-message">
          登録内容をご確認ください。
        </div>
        <div class="user_register_confirm__body__form">
          <form class="horizontal" @submit="onSubmit">
            <div class="user_register_confirm__body__form__infomation">
              <div class="form-ctrl">
                <label class="text" >
                  氏名
                  <span class="required-text">（必須）</span>
                  <br />
                  <span class="text-active-2">※公開されません</span>
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                  {{ state.userRegisterData.family_name }}
                  &nbsp;&nbsp;
                  {{ state.userRegisterData.given_name }}
                </div>
              </div>
              <div class="form-ctrl">
                <label class="text" >
                  （氏名）カナ
                  <span class="required-text">（必須）</span>
                  <br />
                  <span class="text-active-2">※公開されません</span>
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                  {{ state.userRegisterData.family_name_kana }}
                  &nbsp;&nbsp;
                  {{ state.userRegisterData.given_name_kana }}
                </div>
              </div>
              <div class="form-ctrl" v-if="state.userRegisterData.nickname">
                <label class="text" >
                  ニックネーム
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                  {{ state.userRegisterData.nickname }}
                </div>
              </div>
                <div class="form-ctrl">
                <label class="text" >
                  居住地
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                  <span v-if="state.userRegisterData.residence_prefecture_name">{{ state.userRegisterData.residence_prefecture_name }} {{ state.userRegisterData.residence_city_name }}</span>
                  <span v-else>-</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;※
                   <span class="publish-type">
                    {{
                      state.userRegisterData.is_public_prefecture
                        ? "  公開する"
                        : "  公開されません"
                    }}
                  </span>
                </div>
              </div>
              <div class="form-ctrl">
                <label class="text" >
                  言語
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                  {{ state.userRegisterData.language || "-"}}
                   &nbsp;&nbsp;&nbsp;&nbsp;※
                  <span class="publish-type">
                    {{
                      state.userRegisterData.is_public_language
                        ? "  公開する"
                        : "  公開されません"
                    }}
                  </span>
                </div>
              </div>
              <div class="form-ctrl" v-if="state.userRegisterData.degree">
                <label class="text" >
                  最終学位
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                   {{ state.userRegisterData.degree }}
                   &nbsp;&nbsp;&nbsp;&nbsp;※
                  <span class="publish-type">
                    {{
                      state.userRegisterData.is_public_degree
                        ? "  公開する"
                        : "  公開されません"
                    }}
                      </span>
                </div>
              </div>
              <div class="form-ctrl">
                <label class="text">
                  学校名
                  <br />
                  <span class="text-active-2">※入力した場合は公開されます</span>
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                   {{ state.userRegisterData.alma_mater || "-" }}
                </div>
              </div>
              <div class="form-ctrl">
                <label class="text">
                  学部・研究科名
                  <br />
                  <span class="text-active-2">※入力した場合は公開されます</span>
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                    {{ state.userRegisterData.academy_department || "-" }}
                </div>
              </div>
              <div class="form-ctrl">
                <label class="text">
                  卒業年度
                  <br />
                  <span class="text-active-2">※入力した場合は公開されます</span>
                </label>
                <div
                    class="user_register_confirm__body__form__infomation-confirm"
                >
                  {{ graduationYear() }}
                </div>
              </div>
              <div class="form-ctrl" v-if="state.userRegisterData.graduation">
                <label class="text" >
                    卒業区分
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                  {{ state.userRegisterData.graduation }}
                  &nbsp;&nbsp;&nbsp;&nbsp;※
                  <span class="publish-type">
                    {{
                      state.userRegisterData.is_public_graduation
                        ? "  公開する"
                        : "  公開されません"
                    }}
                  </span>
                </div>
              </div>
              <div class="form-ctrl" v-if="state.userRegisterData.society_type">
                <label class="text" >
                  社会人 / 学生
                </label>
                <div
                  class="user_register_confirm__body__form__infomation-confirm"
                >
                  {{ state.userRegisterData.society_type }}
                  &nbsp;&nbsp;&nbsp;&nbsp;※
                  <span class="publish-type">
                    {{
                      state.userRegisterData.is_public_society_type
                        ? "  公開する"
                        : "  公開されません"
                    }}
                  </span>
                </div>
              </div>

              <div class="form-ctrl">
                <label class="text" >
                    勤務先
                    <br />
                    <span class="text-active-2">※公開されません<br>※現在の勤務先がある場合は必ず入力してください(協働企業側が、競合企業に所属する受講生とのマッチングはNGと判断する場合があるため)</span>
                </label>
                <div class="user_register_confirm__body__form__infomation-confirm">
                  <div class="organization-name">
                    {{ state.userRegisterData.organization_name }}
                  </div>
                  <div class="organization-name" v-if="state.userRegisterData.organization_name_2">
                    {{ state.userRegisterData.organization_name_2 }}
                  </div>
                  <div class="organization-name" v-if="state.userRegisterData.organization_name_3">
                    {{ state.userRegisterData.organization_name_3 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="btn_group">
              <button
                type="button"
                @click="goDetailProfile"
                class="btn-md btn-light-gray shadow"
              >
                入力画面に戻る
              </button>
              <button
                type="submit"
                :disabled="isSubmitting"
                :class="{ submitting: isSubmitting }"
                class="btn-md btn-blue shadow"
              >
                <i v-if="isSubmitting" class="fas fa-circle-notch fa-spin"></i
                >登録する
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <Alert v-if="state.showPopupAlert" content="ユーザー登録の処理をしております<br>5分ほど時間がかかる場合がございます。<br>完了までしばらくお待ちください。"/>
</template>

<script lang="ts" src="@/presentation/views/user/register/confirm/Confirm.ts"></script>

<style lang="scss">
@import "@/presentation/views/user/register/confirm/Confirm.scss";
</style>
