var User = /** @class */ (function () {
    function User() {
        this.family_name = "";
        this.given_name = "";
        this.family_name_kana = "";
        this.given_name_kana = "";
        this.residence_prefecture_id = null;
        this.residence_city_id = null;
        this.residence_prefecture_name = "";
        this.residence_city_name = "";
        this.society_type = "";
        this.society_publish_type = "";
        this.language = "";
        this.nickname = "";
        this.is_mail_receivabled = 0;
        this.organization_name = "";
        this.corporate_number = "";
        this.organization_name_2 = "";
        this.corporate_number_2 = "";
        this.organization_name_3 = "";
        this.corporate_number_3 = "";
        this.reason_no_work_place = "";
        this.profile_publish_type = "";
        this.register_progress = "";
        this.graduation_year = 0;
    }
    return User;
}());
export default User;
