import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import DIContainer from "@/core/DIContainer";
import { useStore } from 'vuex';
import { useForm } from "vee-validate";
import User from "@/domain/entity/User";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import routePath from "@/commons/RoutePath";
export default defineComponent({
    name: "UserRegisterConfirm",
    components: {
        Breadcrumb: Breadcrumb,
        Alert: Alert
    },
    setup: function () {
        var _a = useForm(), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit;
        var store = useStore();
        var router = useRouter();
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var state = reactive({
            listBreadcrumb: [
                {
                    route: routePath.TOP,
                    name: "TOP"
                },
                {
                    route: "",
                    name: "基本情報確認"
                }
            ],
            userRegisterData: new User(),
            showPopupAlert: false,
        });
        function goDetailProfile() {
            store.commit('setTermsAgreed', true);
            router.push("./profile");
        }
        var onSubmit = handleSubmit(function () {
            state.showPopupAlert = true;
            state.userRegisterData.register_progress = "COMPLETED";
            return userInteractor.updateInforUser(state.userRegisterData).then(function (res) {
                store.commit("setUserRegisterData", {});
                router.push(routePath.USER_REGISTER_COMPLETED);
            }).catch(function (error) {
            });
        });
        function initData() {
            var data = store.state.userRegisterData;
            if (!data.id ||
                !data.family_name ||
                !data.given_name ||
                !data.family_name_kana) {
                return router.push(routePath.USER_REGISTER_PROFILE);
            }
            state.userRegisterData = data;
        }
        function convertLanguage(value) {
            var data = {
                en: "英語",
                ja: "日本語",
                zh: "中国語"
            };
            return data[value];
        }
        function graduationYear() {
            if (state.userRegisterData.graduation_year == 1) {
                return '在学中';
            }
            else if (state.userRegisterData.graduation_year) {
                return state.userRegisterData.graduation_year + '年度';
            }
            else {
                return '-';
            }
        }
        onMounted(function () {
            initData();
        });
        return {
            state: state,
            goDetailProfile: goDetailProfile,
            onSubmit: onSubmit,
            convertLanguage: convertLanguage,
            isSubmitting: isSubmitting,
            graduationYear: graduationYear,
        };
    }
});
